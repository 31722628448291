import React, { useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import emailjs from "@emailjs/browser";
import Industries from "../../assets/data";
const Modal = ({ handleCloseModal }) => {
  const [errors, setErrors] = useState({});
  const form = useRef();
  let products = Industries.map((industry) => (
    industry.products.map((product) => product)
  ));

  const sendEmail = (e) => {
    e.preventDefault();
    // Validate form fields

    const nameInput = form.current["name"];
    const numberInput = form.current["number"];
    const productInput = form.current["product"];
    const errors = {};

    if (!nameInput.value.trim()) {
      errors.name = "Name is required";
    }

    if (!numberInput.value.trim()) {
      errors.number = "Phone Number is required";
    } else if (!/^\d{10}$/.test(numberInput.value)) {
      errors.number = "Invalid phone number format";
    }

    if (!productInput.value.trim()) {
      errors.product = "Product is required";
    }

    if (Object.keys(errors).length > 0) {
      // Display errors and prevent form submission
      setErrors(errors);
      return;
    }
    
    emailjs
      .sendForm(
        "service_4kpsvkr",
        "template_tpkoslt",
        form.current,
        "nQX06j-SlXto14zxz"
      )
      .then(
        (result) => {
          console.log(result.text);
          handleCloseModal("success");
        },
        (error) => {
          console.log(error.text);
          handleCloseModal("error");
        }
      );

    // Clear errors
    setErrors({});
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
        <button
          className=" text-black grid place-content-center p-2 rounded-full w-10 h-10 right-10 top-10 absolute bg-white"
          onClick={handleCloseModal}
        >
          <RxCross2 />
        </button>
        <form
          ref={form}
          onSubmit={sendEmail}
          className="bg-white p-4 md:p-8 lg:p-12 mx-12 md:mx-8 lg:mx-12 rounded-lg max-w-md w-full"
        >
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
            Demo Details
          </h2>

          <div className="mb-4">
            <label
              htmlFor="from_name"
              className="block text-sm md:text-base lg:text-lg font-medium text-gray-600"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="from_name"
              // required
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-violet-300"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>

          <div className="mb-4">
            <label
              htmlFor="phone_number"
              className="block text-sm md:text-base lg:text-lg font-medium text-gray-600"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="number"
              maxLength="10"
              title="Ten digits code"
              name="phone_number"
              // required
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-violet-300"
            />
            {errors.number && <p className="text-red-500">{errors.number}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="product"
              className="block text-sm md:text-base lg:text-lg font-medium text-gray-600"
            >
              Product
            </label>
            {/* <input
              type="text"
              id="product"
              name="product"
              // required
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-violet-300"
            /> */}
            <select
              id="product"
              name="product"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option selected>Choose a Product</option>
              {products.flat().map((product) => (
                <option value={product?.name}>{product?.name}</option>
              ))}
            </select>
            {errors.product && <p className="text-red-500">{errors.product}</p>}
          </div>
          <div className="mb-4">
            <label
              htmlFor="product"
              className="block text-sm md:text-base lg:text-lg font-medium text-gray-600"
            >
              Others
            </label>
            <textarea
              type="text"
              id="other"
              name="other"
              // required
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-violet-300"
            />
            
          </div>
          <button
            className="bg-violet-500 text-white p-2 rounded-md hover:bg-violet-700 focus:outline-none focus:ring focus:border-violet-300"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default Modal;
