import React from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/salon-1.jpg";
import image2 from "../../assets/images/salon-2.jpg";
import image3 from "../../assets/images/salon-3.jpg";
import Footer from "../Footer";
const SalonManagement = () => {
  return (
    <div className="w-screen h-screen flex flex-col">
      <Navbar color={"black"} />
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            DIGITHACK’S SALON MANAGEMENT SOFTWARE
          </h1>
        </div>

        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          At Digithack, we are dedicated to revolutionizing the way businesses
          manage their processes. With a commitment to innovation and
          user-centric solutions, we offer a comprehensive Salon Management
          software designed to simplify invoicing and streamline operations.
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-3xl font-semibold">Our Features</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">Web Based:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Our software supports mobile devices such as smartphones and tablets
            or Laptops.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This functionality empowers businesses to effortlessly process
            payments and efficiently manage sales transactions on the move,
            eliminating the need for dedicated billing counters.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Credit Management:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Salon software keeping track of how much money your customers owe
            you and making sure they pay it back on time.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It also involves deciding how much credit to give to each customer
            and checking if they can be trusted to pay you back.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Create Staff/ Define User Control:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            The management and regulation of user access and permissions within
            the application.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It involves defining and assigning specific roles and privileges to
            staff members or users based on their responsibilities and
            requirements
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">Service Reminder:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This feature allows salon owners to automatically send personalized
            reminders to clients about their upcoming appointments via email,
            SMS, or WhatsApp. By providing timely notifications, it helps reduce
            no-shows and ensures clients never miss their scheduled services.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          GST Reports of Sale & Purchase:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            A GST report of sale and purchase is a record that shows all the
            things a business sold and bought in a certain time, along with the
            taxes involved. It helps the business follow the rules and calculate
            the right amount of taxes they owe.
          </li>
        </ul>
        <img src={image2} alt="" className="mx-auto w-1/2" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          WhatsApp API Integration with Domain Name:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Our software’s WhatsApp API Integration with your salon's domain
            name facilitates seamless communication between your salon and its
            clients. By integrating WhatsApp into your salon management system,
            you can send appointment confirmations, reminders, promotional
            messages, and respond to client inquiries directly through WhatsApp.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Cloud Data Storage:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Cloud data storage for a billing app means keeping all the important
            billing information, like invoices and customer details, on the
            internet instead of on your computer.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It's like having a virtual safe place for your data that you can
            access from anywhere with an internet connection.
          </li>
        </ul>

        <h2 className="text-xl lg:text-2xl font-semibold">
          Generate Bill In One Click:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Clicking once to create a bill simplifies and accelerates the
            billing process. It's like a shortcut for billing. It saves time and
            enhances efficiency, making it a valuable feature for businesses and
            individuals who need to create invoices quickly and effortlessly.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Track Your Inventory:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Built-in functionality allows you to track inventory/stock.
            Auto-update inventory whenever you create an Invoice.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Notify you about low stock, stock availability and more.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Loyalty and Reward Module with Notifications:
        </h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            The Loyalty and Reward Module is an integral part of our Salon
            Management Software, designed to boost client retention and
            encourage repeat business. This feature enables salons to create a
            points-based reward system where clients earn points for each visit
            or service availed. Points can be redeemed for discounts, free
            services, or exclusive offers.
          </li>
        </ul>
        <h2 className="text-xl lg:text-2xl font-semibold">Customer Wallet:</h2>
        <ul className="list-none lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            This wallet is associated with a specific customer and holds any
            credit balances or extra payments they may have made.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            It simplifies financial transactions for both customers and
            businesses.
          </li>
        </ul>

        <div className="flex justify-center flex-wrap items-center w-full">
          <img src={image3} alt="" />
          <div className="">
            <h2 className="text-xl lg:text-2xl font-semibold">
              Ready to Streamline Your Operations?
            </h2>
            <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
              Transform your workplace efficiency and customer satisfaction with
              our Salon management software.
            </p>
          </div>
        </div>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Contact us to schedule a demo or discuss how our software can optimize
          your billing process!
        </h2>
      </div>
      <Footer />
    </div>
  );
};

export default SalonManagement;
