import React from "react";
import { useNavigate } from "react-router-dom";
const ComingSoon = () => {
    const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center text-purple-600 flex-1 flex-col gap-4">
      <h1 className="text-8xl">Coming Soon</h1>
      <button
        className="md:text-lg mt-4 text-sm border-2 border-violet-700 rounded-lg p-2 "
        onClick={() => {
          navigate("/category");
        }}
      >
        Explore all Products
      </button>
    </div>
  );
};

export default ComingSoon;
