import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
const Card = ({ item, btn }) => {

  const navigate = useNavigate();
  return (
    <div className="flex flex-col mt-6 group text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96 relative">
      <div className="icon-container w-25 px-6 pt-4">{item.icon}</div>
      <div className="p-6">
        <h5 className="block mb-2 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
          {item.title}
        </h5>
        <p className="block font-sans text-base antialiased font-light leading-relaxed text-inherit">
          {item.description}
        </p>
      </div>
      <div className="p-6 absolute bottom-0 left-0 rounded-xl w-full h-0 overflow-hidden transition-all hidden group-hover:h-full group-hover:block bg-white z-[3]">
        {item.products.map((product, index) => (
          <Link to={`/category/${product?.link}`} key={index} >
          <button  className="p-1 bg-violet-200 m-1 text-sm rounded-lg ">
            {product.name}
          </button>
          </Link>
        ))}
      </div>
      <div className={`p-6 pt-0 z-[8] justify-end ${btn ? "flex" : "hidden"}`}>
        <button
          className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-Cdark text-white shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
          type="button"
          onClick={() => {
            navigate("/category");
          }}
        >
          <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export const AnimatedCard = () => {
  const navigate = useNavigate();
  return (
    <div className="relative flex flex-col mt-6 text-gray-700 justify-center items-center bg-clip-border rounded-xl w-96">
      <div className="arrow">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <button
        className="md:text-lg mt-4 text-sm border-2 border-violet-700 rounded-lg p-2"
        onClick={() => {
          navigate("/category");
        }}
      >
        Explore all Categories
      </button>
    </div>
  );
};

export default Card;
